import { graphql } from 'gatsby'
import Custom from '../containers/Custom'

export default Custom

export const pageQuery = graphql`
  query CustomPageQuery(
    $id: String!
  ) {
    customInformation: contentfulCustomPage (id: { eq: $id }) {
      id
      title
      headerTitle
      seoTitle
      seoDescription
      createdAt
      contentful_id
      content {
        raw
        references {
          ... on ContentfulAsset {
            # contentful_id is required to resolve the references
            __typename
            id
            contentful_id
            title
             fluid(maxWidth:1200) {
                src
              }
          },
          ... on ContentfulNewsletterBox {
            # contentful_id is required to resolve the references
            __typename
            id
            contentful_id
            buttonTitle
            description
            headerTitle
            picture {
              fixed(width: 900) {
                src
              }
            }
          },
          ... on ContentfulPartner {
            __typename
            id
            contentful_id
            title
            description
            buttonTitle
            link
            picture {
              fluid (maxWidth: 300){
                src
              }
            }
          },
          ... on ContentfulTestimony {
            __typename
            id
            contentful_id
            title
            content{
              content
            }
            clientName
          },
          ... on ContentfulCustomText {
            __typename
            id
            contentful_id
            value
            style
          },
          ... on ContentfulCtaButton {
            __typename
            id
            contentful_id
            buttonTitle
            link
          },
          ... on ContentfulVideo{
            __typename
            id
            contentful_id
            title
            url
          },
          ... on ContentfulImageWithLink {
            __typename
            id
            openOtherTab
            contentful_id
            title
            image {
              title
              fluid{
                src
              }
            }
            url
          },
          ... on ContentfulTwoColumnsContent {
            __typename
            id
            contentful_id
            firstColumn {
              raw
              references {
                ... on ContentfulAsset {
                  # contentful_id is required to resolve the references
                  __typename
                  id
                  title
                  contentful_id
                  fluid{
                    src
                  }
                },
                ... on ContentfulImageWithLink {
                  __typename
                  id
                  openOtherTab
                  contentful_id
                  image {
                    title
                    fluid{
                      src
                    }
                  }
                  url
                }
              }
            }
            secondColumn {
              raw
              references {
                ... on ContentfulAsset {
                  # contentful_id is required to resolve the references
                  __typename
                  id
                  contentful_id
                  title
                  fluid{
                    src
                  }
                },
                ... on ContentfulImageWithLink {
                  __typename
                  id
                  openOtherTab
                  contentful_id
                  image {
                    title
                    fluid{
                      src
                    }
                  }
                  url
                }
              }
            }
          },
          ... on ContentfulThreeColumnsContent {
            __typename
            id
            contentful_id
            firstColumn {
              raw
              references {
                ... on ContentfulAsset {
                  # contentful_id is required to resolve the references
                  __typename
                  id
                  contentful_id
                  title
                  fluid{
                    src
                  }
                },
                ... on ContentfulImageWithLink {
                  __typename
                  id
                  openOtherTab
                  contentful_id
                  image {
                    title
                    fluid{
                      src
                    }
                  }
                  url
                }
              }
            }
            secondColumn {
              raw
              references {
                ... on ContentfulAsset {
                  # contentful_id is required to resolve the references
                  __typename
                  id
                  contentful_id
                  title
                  fluid{
                    src
                  }
                },
                ... on ContentfulImageWithLink {
                  __typename
                  id
                  openOtherTab
                  contentful_id
                  image {
                    title
                    fluid{
                      src
                    }
                  }
                  url
                }
              }
            }
            thirdColumn {
              raw
              references {
                ... on ContentfulAsset {
                  # contentful_id is required to resolve the references
                  __typename
                  id
                  contentful_id
                  title
                  fluid{
                    src
                  }
                },
                ... on ContentfulImageWithLink {
                  __typename
                  id
                  openOtherTab
                  contentful_id
                  image {
                    title
                    fluid{
                      src
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
