import React from 'react'
import { Text, Heading, Container, Box, Button } from 'theme-ui'
import { Stack, Main} from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import TestimonyBlock from '../components/Testimony/Testimony'
import NewsletterCompact from '@widgets/NewsletterCompact/NewsletterCompact'
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import BannerHorizontal from '@widgets/BannerHorizontal'
import { Link } from 'gatsby'

const styles = {
  stack: {
    textAlign: `center`
  },
  ctaEnd:{
    m:3,
    p:3,
    fontSize: `1.5em`,
    width: `75%`,
    borderRadius: 0,
    fontWeight:`bold`,
    color: `#24252a`,
    bg: `#ffe26c`,
    borderColor: `#ffe26c`,
    borderRadius: `20px`,
    boxShadow: `10px 10px 10px rgba(0, 0, 0, 0.5)`,
    ':hover': {
      color: `white`,
      bg: `#D4BC5C`,
      borderColor: `#D4BC5C`
    }
  },
  box: {
    borderLeft: `5px solid`,
    borderLeftColor: `omegaLighter`,
    pl: 5,
    py: 2,
    my: 5
  },
  quote: {
    color: `omegaDark`,
    fontWeight: `body`
  },
  writer: {
    color: `omegaDark`,
    mb: 0
  },
  fullScreenBox: {
    width:`100%`,
  },
  box2 : {
    verticalAlign: `top`,
    padding:`0.4rem`,
    textAlign: `left`,
  },
  box3 : {
    verticalAlign: `top`,
    padding:`0.4rem`,
    textAlign: `left`,
  },
  headerBox: {
    marginBottom:`2rem`,
    marginTop:`1rem`
  },
  h1:{
    fontSize: `2.5rem`,
    color: `white`,
    textAlign: `center`,
    padding: `0.75rem`,
    margin:`1rem`
  },
  h2 : {
    fontSize: `2rem`,
    color: `white`,
    textAlign: `center`,
    padding: `0.75rem`,
    margin:`1rem`
  },
  h3 : {
    fontSize: `1.7rem`,
    color: `white`,
    textAlign: `center`,
    padding: `0.75rem`,
    margin:`1rem`
  },
  h4 : {
    fontSize: `1.5rem`,
    color: `#be7155`,
    textAlign: `center`,
    padding: `0.75rem`,
    margin:`1rem`
  },
  h5: {
    fontSize: `1.1rem`,
    color: `white`,
    textAlign: `left`,
    padding: `0.75rem`,
    margin:`1rem`
  },
  h6 : {
    fontSize: `1.1rem`,
    color: `white`,
    textAlign: `left`,
    padding: `0.75rem`,
    margin:`1rem`
  },
  blockquote: {
    padding: 0,
    margin: 0,
    marginBottom: `1rem`,
    marginLeft: 0,
    marginRight: 0,
    fontStyle: `italic`,
    borderLeftStyle: `solid`,
    borderLeftColor: `#a3bffa`,
    borderLeftWidth: `3px`,
    paddingLeft: `1rem`,
    paddingRight: `1rem`
  }
}

const Custom = ({
  data: { customInformation },
  location,
  ...props
}) => {

 	const { pageContext: { services = {}, siteUrl } = {}, path } = props


  const optionsColumns = {
    renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => <Heading as="h1">{children}</Heading>,
        [BLOCKS.HEADING_2]: (node, children) => <Heading as="h2">{children}</Heading>,
        [BLOCKS.HEADING_3]: (node, children) => <Heading as="h3">{children}</Heading>,
        [BLOCKS.HEADING_4]: (node, children) => <Heading as="h3">{children}</Heading>,
        [BLOCKS.HEADING_5]: (node, children) => <p style={{textAlign:`center`}}>{children}</p>,
        [BLOCKS.PARAGRAPH]: (node, children) => <p sx={{fontSize: `1em`, marginBottom:`2rem`}}>{children}</p>,
        [BLOCKS.QUOTE]: (node, children) => <Container sx={styles.blockquote}>{children}</Container>,
        [BLOCKS.EMBEDDED_ASSET]: node => {
            const {
              fluid: { src },
              title,
            } = node.data.target
            
          return <Container sx={{textAlign:`center`}}><img src={src} alt={title} style={{marginBottom:`2rem`, maxWidth:`100%`}} /></Container>
        },
        [BLOCKS.EMBEDDED_ENTRY]: node => {
            const {__typename} = node.data.target
            const target = node.data.target
            let returnValue = "";

            switch(__typename){
              case "ContentfulImageWithLink":
                returnValue = <Container sx={{textAlign:`center`}}><a href={target.url} target={target.openOtherTab ? '_blank' : ''}><img src={target.image.fluid.src} alt={target.image.title} style={{marginBottom:`2rem`, maxWidth:`100%`}} /></a></Container>
              break;
            }

            return returnValue
        }
    }
  };

  //Render Rich Text from Cotentful
  const options = {
      renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
            <Stack effectProps={{ effect: 'none' }}>
              <Main>
                <ul>
                {children.map((item) => (
                  <li key={item.key}>{item}</li>
                ))}
              </ul>
            </Main>
            </Stack>
          )
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
            <Stack effectProps={{ effect: 'none' }}>
                  <Main>
                    <ul>
              {children.map((item) => (
                      <li key={item.key}>{item}</li>
                    ))}
              </ul>
            </Main>
            </Stack>
          )
      },
        [BLOCKS.HEADING_1]: (node, children) => {
          return (
            <Box bg="#24252a" sx={styles.fullScreenBox, styles.headerBox}>
                <Stack effectProps={{ effect: 'none' }}>
                  <Main>
                    <Heading style={styles.h1} as="h2">{children}</Heading>
                  </Main>
                </Stack>
            </Box>
          )
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return (
            <Box bg="#BE7155" sx={styles.fullScreenBox, styles.headerBox}>
                <Stack effectProps={{ effect: 'none' }}>
                  <Main>
                    <Heading style={styles.h2} as="h2">{children}</Heading>
                  </Main>
                </Stack>
            </Box>
          )
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return (
            <Box bg="#6C91A4" sx={styles.fullScreenBox, styles.headerBox}>
                <Stack effectProps={{ effect: 'none' }}>
                  <Main>
                      <Heading style={styles.h3} as="h3">{children}</Heading>
                  </Main>
                </Stack>
            </Box>
          )
        },
        [BLOCKS.HEADING_4]: (node, children) => {
          return (
            <Box bg="#24252a" sx={styles.fullScreenBox, styles.headerBox}>
                <Stack effectProps={{ effect: 'none' }}>
                  <Main>
                    <Heading style={styles.h4} as="h4">{children}</Heading>
                  </Main>
                </Stack>
            </Box>
          )
        },
        [BLOCKS.HEADING_5]: (node, children) => {
          return (
            <Stack effectProps={{ effect: 'none' }} sx={styles.headerBox}>
                  <Main>
                <Box bg="#BE7155">
                      <Heading style={styles.h5} as="h5">{children}</Heading>
                </Box>
              </Main>
            </Stack>
          )
        },
        [BLOCKS.HEADING_6]: (node, children) => {
          return (
            <Stack effectProps={{ effect: 'none' }} sx={styles.headerBox}>
                  <Main>
                <Box bg="#6C91A4">
                      <Heading style={styles.h6} as="h6">{children}</Heading>
                </Box>
              </Main>
            </Stack>
          )
        },
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <Stack effectProps={{ effect: 'none' }}>
            <Main>
              <Text sx={{fontSize: `1em`, color:`#4a5568`, marginBottom:`1rem`}}>{children}</Text>
            </Main>
          </Stack>
        ),
        [BLOCKS.QUOTE]: (node, children) => (
            <Stack effectProps={{ effect: 'none' }}>
                <Main>
              <Container sx={styles.blockquote}>{children}</Container>
            </Main>
          </Stack>
        ),
      [BLOCKS.EMBEDDED_ASSET]: node => {
          const {
            fluid: {src},
            title,
          } = node.data.target

        return (
            <Stack effectProps={{ effect: 'none' }}>
              <Main sx={{textAlign:`center`}}>
                <img src={src} alt={title} style={{marginBottom:`2rem`, maxWidth:`100%`}} />
              </Main>
          </Stack>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
          const {__typename} = node.data.target
          const target = node.data.target
          let returnValue = "";

          switch(__typename){
            case "ContentfulPartner":
              returnValue = (
                <>
                    <BannerHorizontal 
                      title={target.title}
                      link={target.link}
                      description={target.description}
                      picture={target.picture}
                      buttonTitle={target.buttonTitle}
                    /><Divider /></>
              );
            break;
            case "ContentfulTestimony":
              returnValue = <TestimonyBlock key={target.id} title={target.title} content={target.content} clientName={target.clientName} />
            break;
            case "ContentfulNewsletterBox":
              returnValue = <NewsletterCompact newsletterInfo={target} simple/> 
            break;
            case "ContentfulImageWithLink":
              returnValue = <Container sx={{textAlign:`center`}}><a href={target.url} target={target.openOtherTab ? '_blank' : ''}><img src={target.image.fluid.src} alt={target.image.title} style={{marginBottom:`30px`, maxWidth:`100%`}} /></a></Container>
            break;
            case "ContentfulTwoColumnsContent":
              returnValue = (
                <table>
                  <tr>
                    <td style={styles.box2}>
                      {renderRichText(target.firstColumn, optionsColumns)}
                    </td>
                    <td style={styles.box2}>
                      {renderRichText(target.secondColumn, optionsColumns)}
                    </td>
                  </tr>
                </table>
              );
            break;
            case "ContentfulThreeColumnsContent":
              returnValue = (
                <table>
                  <tr>
                    <td style={styles.box3}>
                      {renderRichText(target.firstColumn, optionsColumns)}
                    </td>
                    <td style={styles.box3}>
                      {renderRichText(target.secondColumn, optionsColumns)}
                    </td>
                    <td style={styles.box3}>
                      {renderRichText(target.thirdColumn, optionsColumns)}
                    </td>
                  </tr>
                </table>
              );
            break;
            case "ContentfulCtaButton":
              returnValue = (
                <Container sx={{textAlign:`center`}}><Button as={Link} href={target.link} sx={styles.ctaEnd}>{target.buttonTitle}</Button></Container>
              );
            break;
            case "ContentfulVideo":
                returnValue = (
                  <Container sx={{textAlign:`center`}}>
                    <iframe 
                      width="640" 
                      height="360"
                      title={target.title} 
                      src={target.url}
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
                      frameborder="0" 
                      allowFullScreen
                    ></iframe>
                  </Container>
                );
              break;
          }

          return (
            <Stack effectProps={{ effect: 'none' }}>
              <Main>
                    {returnValue}
              </Main>
            </Stack>
          )
      },
			[INLINES.EMBEDDED_ENTRY]: node => {
				const {__typename} = node.data.target
				const target = node.data.target

				switch(__typename){
					case "ContentfulVideo":
						return (
							<Stack effectProps={{ effect: 'none' }}>
								<Main>
									<Container sx={{textAlign:`center`}}>
									<iframe 
										width="640" 
										height="360"
										title={target.title} 
										src={target.url}
										allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" 
										frameborder="0" 
										allowFullScreen
									></iframe>
									</Container>
								</Main>
							</Stack>
						);

					case "ContentfulCustomText":
						return (
						  <strike>{target.value}</strike>
						);
				}
		  },
      }
  };
	return (
	    <>  
			<Seo 
        title={customInformation.title+' | Comment devenir rentier'} 
        siteUrl={siteUrl+path} 
        seoTitle={customInformation.seoTitle} 
        seoDescription={customInformation.seoDescription}
      />
      <Stack effectProps={{ effect: 'none' }}>
        <Main>
          <Heading as='h1' style={{marginTop:`3rem`, fontSize:`2.5em`}}>{customInformation.headerTitle}</Heading>
        </Main>
      </Stack>
      {customInformation.content && renderRichText(customInformation.content, options)}
			<Divider />
	    </>
	)
}

export default Custom
